import { GetTypeIfValid, ProcessPipelineConfig } from "./helper";

export function handleClick(){
    var inputBody = document.querySelector("#inputBody input");
    var outputBody = document.querySelector("#outputBody input");
    
    if(inputBody.value == null || inputBody.value == ""){
        alert('Invalid payload');
        return;
    }
    var inputValue = inputBody.value;

    let { isValid, settingType } = GetTypeIfValid(inputValue);
    if(isValid){
        alert(`${settingType} is a valid type`);
        outputBody.value = inputValue;
    }
    else {
        alert('Invalid payload');
    }
}


