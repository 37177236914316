import { COMPARISON_BINARY_OPERATORS } from "@babel/types";
import { configTypes } from "./constants";

let [PORTAL_CONFIG, PIPELINE_CONFIG, LOCAL_CORE_CONFIG, LOCAL_FUNCTION_CONFIG] = configTypes;


export function ProcessPipelineConfig(data, jsonData){
    let settings  = data.split(" -");
    let settingArray = Object.values(settings);
    let keyValuePairs = settingArray.map(item => {
        let [key] = item.split(" ", 1);
        let value = item.substring(item.indexOf(" "));
        // console.log(key, value);
        key = key.trim().substring(1);
        return {
            key,
            value
        }
    })
 }

export function GetTypeIfValid(data) {
    let settingType = "";
    let isValid = false;
    var isJson = tryParseJSONObject(data);
    if (!isJson) {
        if (isPipelineConfig(data)) {
            isValid = true;
            settingType = PIPELINE_CONFIG;
            ProcessPipelineConfig(data, isJson);
            return { isValid, settingType };
        }
        return { isValid, settingType };
    }
    if (isPortalConfig(data, isJson)) {
        isValid = true;
        settingType = PORTAL_CONFIG;
        return { isValid, settingType };
    }
    if (isFunctionConfig(data, isJson)) {
        isValid = true;
        settingType = LOCAL_FUNCTION_CONFIG;
        return { isValid, settingType };
    }
    if (isCoreConfig(data, isJson)) {
        isValid = true;
        settingType = LOCAL_CORE_CONFIG;
        return { isValid, settingType };
    }

    return { isValid, settingType };

}

export function getType(data) {
    if (isLocalFile(data)) {
        if (isFunctionConfig(data)) {
            return LOCAL_FUNCTION_CONFIG;
        }
        if (isCoreConfig(data)) {
            return LOCAL_CORE_CONFIG;
        }
    }
    else if (isPortalConfig(data)) {
        return PORTAL_CONFIG;
    }
    else if (isPipelineConfig(data)) {
        return PIPELINE_CONFIG;
    }
}


export function isPipelineConfig(data) {
    data = data.trim();
    if (data.charAt(0) == "-") {
        return true;
    }
    if (data.split(" -").length - 1) {
        return true;
    }
    return false;
}

//all settings in the portal have same 3 keys (name, value, slotsetting)
export function isPortalConfig(data, jsonData) {

    let isObjArray = Array.isArray(jsonData);
    try {        
        var settingInstances = Object.values(jsonData);
        var nameKeys = Object.values(jsonData).filter(settingObj => ("name" in settingObj) && ("value" in settingObj));
        //this means every json object within the array has the "name" and "value" properties
        if (nameKeys.length == settingInstances.length) {
            return true;
        }
    }
    catch (e) {
        console.log(e);
        return false;
    }

}


export function isFunctionConfig(data, jsonData) {
    //look presence of values in json array
    if (('Values' in jsonData)) {
        return true;
    }
}

export function isCoreConfig(data, jsonData) {
    if ('AppSettings' in jsonData) {
        return true;
    }
    if (jsonData.Logging != null && jsonData.Logging != undefined && jsonData.Logging != "") {
        return true;
    }
}
/**
 * If you don't care about primitives and only objects then this function
 * is for you, otherwise look elsewhere.
 * This function will return `false` for any valid json primitive.
 * EG, 'true' -> false
 *     '123' -> false
 *     'null' -> false
 *     '"I'm a string"' -> false
 */
export function tryParseJSONObject(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};