import {handleClick} from './events';

const pipelineConfig = document.querySelector('#pipelineConfig');
const portalConfig = document.querySelector('#portalConfig');
const localSettings = document.querySelector('#localSettings');

pipelineConfig.addEventListener('click',handleClick);

portalConfig.addEventListener('click', function(){
    console.log('it was clicked!2');
});

localSettings.addEventListener('click', function(){
    console.log('it was clicked! 3');
});

